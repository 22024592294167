<template>
  <div class="tutor">
    <div class="header">
      <div class="center">
        {{ title }}
      </div>
      <div class="right"></div>
    </div>
    <div class="main">
      <div class="info-1">{{ text }}</div>
      <div class="img">
        <img :src="wechatUrl" alt="" />
      </div>
      <!-- <div class="btn">
                <button class="copy">
                    截图到微信扫码
                </button>
            </div> -->
      <div class="btn">
        <button class="copy" @click="onCopyPassword">
          一键复制微信号
        </button>
        <textarea v-model="wechatId" id="copy-textarea" />
      </div>
    </div>
    <!-- <div class="footer">
            <footerMenu></footerMenu>
        </div> -->
  </div>
</template>

<script>
import { Notify } from "vant";
import { TouchEmulator } from "@/util/touch.js";
import footerMenu from "@/components/footerMenu.vue";
import { flexQrcodeApi } from "@/api/flexQrcodeApi.js";
export default {
  components: {
    // footerMenu,
  },
  data() {
    return {
      wechatId: "hepulaocai",
      wechatUrl: require("@/assets/images/zhujiao0.png"),
      title: "添加老师～",
      text: "扫码领牛批的教资冲刺资料/咨询价格美丽的保过班",
    };
  },
  methods: {
    onCopyPassword() {
      this.$nextTick(() => {
        const text = document.getElementById("copy-textarea");
        text.select();
        if (document.execCommand("copy")) {
          document.execCommand("copy");
          Notify({
            type: "success",
            message: "已复制微信号:" + this.wechatId,
          });
        }
      });
    },
    getQRCode(id) {
      if (!id) {
        return;
      }
      flexQrcodeApi.qrcode(id).then((ret) => {
        if (ret.code == 0 && ret.data) {
          console.log(ret);
          const item = ret.data;
          this.title = item.title;
          this.text = item.text;
          document.title = this.text;
          this.wechatId = item.weChat.wechatId;
          this.wechatUrl = item.weChat.url;
        }
      });
    },
  },
  mounted() {
    const id = 2;
    this.getQRCode(id);
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.tutor {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
  .header {
    height: 68px;
    flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    margin: 0 0 12px 0;
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 20px;
      color: #323233;
      letter-spacing: 0.44px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .main {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    height: 500px;
    width: auto;
    margin: 20px 18px;
    background: #ffffff;
    border-radius: 12px;
    align-items: center;
    .info-1 {
      font-weight: 500;
      font-size: 20px;
      color: #363637;
      letter-spacing: 3.92px;
      text-align: center;
      margin: 20px;
    }
    img {
      width: 220px;
      height: auto;
      margin: 0 0 20px 0;
    }
    .btn {
      display: flex;
      flex-direction: column;
      align-content: center;
      .copy {
        background: #ff8423;
        border-radius: 12px;
        width: 280px;
        height: 46px;
        border: none;
        font-size: 20px;
        color: #ffffff;
        letter-spacing: 0.44px;
        font-weight: 500;
      }
      #copy-textarea {
        position: absolute;
        left: -99999px;
      }
    }
  }
  .footer {
    flex: 0 0 auto;
  }
}
</style>
